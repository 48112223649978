<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <div class="mb-2">
        <h4><i class="fa fa-plus" /> เพิ่มผู้ช่วย </h4>
      </div>
      <b-form>
        <b-row>
          <!-- username -->
          <b-col cols="4">
            <b-form-group
              label="ชื่อผู้ใช้"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    as
                  </b-input-group-prepend>
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]? 'กรุณากรอกชื่อผู้ใช้ ชื่อผู้ใช้ต้องมีอย่างน้อย 1 ตัวอักษร' :'' }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- password -->
          <b-col cols="4">
            <b-form-group
              label="รหัสผ่าน"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:6"
              >
                <b-input-group>
                  <b-form-input
                    id="a-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldType"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0]? 'กรุณากรอกรหัสผ่าน' :'' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- confirm password -->
          <b-col cols="4">
            <b-form-group
              label="ยืนยันรหัสผ่าน"
              label-for="ac-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group>
                  <b-form-input
                    id="ac-password"
                    v-model="passValue"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldType"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0]? 'กรุณายืนยันรหัสผ่าน' :'' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- ชื่อ-นามสกุล -->
          <b-col cols="6">
            <b-form-group
              label="ชื่อ"
              label-for="firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="firstname"
                rules="required"
              >
                <b-form-input
                  id="firstname"
                  v-model="firstname"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0]? 'กรุณากรอกชื่อ' :'' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="นามสกุล"
              label-for="lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="lastname"
                rules="required"
              >
                <b-form-input
                  id="lastname"
                  v-model="lastname"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกนามสกุล' :'' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="เบอร์โทรศัพท์"
              label-for="tel"
            >
              <b-form-input
                id="tel"
                v-model="tel"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Line ID"
              label-for="line"
            >
              <b-form-input
                id="line"
                v-model="line"
                text="text"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <!-- login button -->
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              <i class="fa fa-plus" /> สร้างผู้ช่วย
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      min,
      passValue: '',
      username: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('login successfully')
        }
      })
    },
  },
}
</script>
